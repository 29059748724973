import React, {useEffect, useState} from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from '@material-ui/core/Button';
import {ConfirmDialog, MaterialTable} from "../../components";
import api from "../../common/api";
import Icon from "@material-ui/core/Icon";
import Batch from './components/batch';
import GroupBatch from './components/groupBatch';
import BatchActivate from './components/batchActivate';
import Mobile from "./components/mobile";
import moment from "moment";
import Switch from '@material-ui/core/Switch';
import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';
import { Tooltip } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    root: props=>({
        margin: props.isDesktop?theme.spacing(4):theme.spacing(1),
    }),
    topBar: {
        marginBottom: theme.spacing(2)
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    progressBar: {
        margin: theme.spacing(2)
    },
    breadcrumbs:{
        marginBottom: theme.spacing(2)
    },
    link: {
        display: 'flex',
    },
    chip: {
        backgroundColor: red[500],
        color: "white",
        fontWeight: 500
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    redChip: {
        justifyContent: 'center',
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        padding: '4px 8px',
        color: '#f44336',
        backgroundColor: 'rgba(244, 67, 54, 0.08)',
    },
    errorMargin:{
        marginBottom: theme.spacing(3),
    },
    typeLabel:{
        borderRadius:"6px",
        padding:"3px",
        fontWeight:"700",
        marginTop:"5px",
        textAlign:"center"
    },
}));

let currentData = undefined;
let sheds = [];
const Batches = ({showError, showMessage, match}) => {
    const farmID = match.params['farmID'];

    const [loading, setLoading] = useState(false);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true
    });
    const classes = useStyles({isDesktop});
    const [batches, setBatch] = useState([]);
    const [groupBatches, setGroupBatch] = useState([]);
    //const [sheds, setShed] = useState([]);
    const [breeds, setBreeds] = useState([]);
    const [showBatchDialog, setShowBatchDialog] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showBatchActivateDialog, setShowBatchActivateDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [showBreedDialog,setBreedDialog] = useState(false)
    const [addBatchError,setAddBatchError] = useState("")
    const [groupErrorMessage, setGroupErrorMessage] = useState('')
    const [showGroupBatchDialog, setShowGroupBatchDialog] = useState(false);
    // eslint-disable-next-line
    const [showGroupBatchView, setShowGroupBatchView] = useState(false);
    const [editMode,setEditmode] = useState(false)
    const [currentGroupBatchData,setCurrentGroupBatchData] = useState({})
    const [groupBachEditMode,setGroupBatchEditMode] = useState(false)
    const [availableSheds,setAvailableSheds]= useState([])
    const [showGroupBatchDeleteConfirm, setGroupBatchShowDeleteConfirm] = useState(false);
    const handleBatchClose = () => {
        currentData = undefined;
        sheds = [];
        setShowBatchDialog(false);
        setShowGroupBatchDialog(false);
        if (errorMessage === "No Breed is Available") {
            setAddBatchError("")
        }
    };

    const addBreedToggle = () => {
        setBreedDialog(true)

    }
    const closeBreedDialog = () => {
        setBreedDialog(false)
    }

    const handleBatchActivateClose = () => {
        currentData = undefined;
        setShowBatchActivateDialog(false);
    };

    const onBatchEdit = (batch) => {
        setEditmode(true)
        getAllSheds(batch);
    };


    const onBreedSave = (breed, setpopuploading) => {
        //setLoading(true);
        removeErrorMessage()
        const response = api.post('breed', {
            ...breed
        });

        response.then(res => {
            setBreeds([...breeds, res]);
            setBreedDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Breed not added');
                else
                setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            setpopuploading(false);
        });
    };
    function onNewBatch(event) {
        setEditmode(false)
        event.preventDefault();
        sheds = [];

        getAllSheds({});
        if (breeds.length === 0) {
            setAddBatchError('No Breed is Available');
        }
        setShowBatchDialog(true);
    }
    // eslint-disable-next-line
    function onNewGroupBatch(event) {
        setGroupBatchEditMode(false)
        getAllShedsForGroupBatch({});
        setCurrentGroupBatchData({})

        if (breeds.length === 0) {
            setAddBatchError('No Breed is Available');
        }
        
    }
    
    const onGroupBatchDelete = (data) => {
        setCurrentGroupBatchData(data)
        setGroupBatchShowDeleteConfirm(true);
    };
    const onDelete = (data) => {
        currentData = data;
        setShowDeleteConfirm(true);
    };

    function handleDeleteConfirmClose() {
        setShowDeleteConfirm(false);
        setGroupBatchShowDeleteConfirm(false)
    }

    const removeErrorMessage = value =>{
        setErrorMessage('');
        setAddBatchError("")
        setGroupErrorMessage("")
    }
    
    const onBatchSave = (batch) => {
        setLoading(true);
        showMessage('');
        const response = api.post(`farm/${farmID}/batch`, {
            ...batch,
            numberOfBirds: parseInt(batch.numberOfBirds, 10),
            isActive:true
        });

        response.then(res => {
            setBatch([...batches, res]);
            setShowBatchDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Batch not added');
                else if(err.message ===  'Duplicate Key')
                    setErrorMessage('Duplicate Name');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onGroupBatchSave = (groupBatch) => {
        setLoading(true);
        showMessage('');
        const response = api.post(`farm/${farmID}/group-batch`, {
            ...groupBatch,
            isGroupBatch:true,
            isActive:true
        });

        response.then(res => {
            setGroupBatch([...groupBatches, res]);
            setShowGroupBatchDialog(false);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setGroupErrorMessage('Batch not added');
                else if(err.message ===  'Duplicate Key')
                    setGroupErrorMessage('Duplicate Name');
                else
                     setGroupErrorMessage(err.message)
            } else {
                setGroupErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    const onBatchUpdate = (batch) => {
        setLoading(true);
        console.debug("On batch update")
        const response = api.post(`farm/${farmID}/batch/${batch.id}`, {
            ...batch,
        });
        response.then(() => {
            let index = batches.findIndex((srcData) => {
                return srcData.id === batch.id;
            });
            batch.oldIsActive = batch.isActive;
            batch.oldShedID = batch.shedID;
            setBatch([...batches.slice(0, index),
                {...batch},
                ...batches.slice(index + 1)]);
            setShowBatchDialog(false);
            setShowBatchActivateDialog(false);
            removeErrorMessage()
        }).catch(err => {
            setShowBatchActivateDialog(true);
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('Batch not updated');
                else if(err.message ===  'Duplicate Key')
                    setErrorMessage('Duplicate Name');
                else if(err.message ===  'Given End Date is Greater than Start Date') {
                    setErrorMessage('Given End Date is Greater than Start Date');
                    setShowBatchActivateDialog(false);
                } else if(err.message.includes('Already there is a daily entries for movement data.')) {
                    setErrorMessage(err.message);
                    setShowBatchActivateDialog(false);
                } else setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
            currentData = undefined;
        });
    };

    const onGroupBatchUpdate = (batch) => {
        setLoading(true);
        console.debug("On Group batch update")
        const response = api.post(`farm/${farmID}/group-batch/${batch.id}`, {
            ...batch,
            isGroupBatch:true,
        });
        response.then(() => {
            let index = groupBatches.findIndex((srcData) => srcData.id === batch.id);
            
            if (index !== -1) {
                setGroupBatch([
                    ...groupBatches.slice(0, index),
                    { ...groupBatches[index], ...batch }, // Update the specific item at 'index'
                    ...groupBatches.slice(index + 1)
                ]);
            }
            
            setShowGroupBatchDialog(false);
            removeErrorMessage();
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setGroupErrorMessage('Batch not updated');
                else if(err.message ===  'Duplicate Key')
                    setGroupErrorMessage('Duplicate Name');
                else if(err.message ===  'Given End Date is Greater than Start Date') {
                    setGroupErrorMessage('Given End Date is Greater than Start Date');
                    setShowBatchActivateDialog(false);
                } else if(err.message.includes('Already there is a daily entries for movement data.')) {
                    setGroupErrorMessage(err.message);
                } else {
                    setGroupErrorMessage(err.message)
                }
            } else {
                setGroupErrorMessage('Unknown Error')
            }
            
        }).finally(() => {
            setLoading(false);
        });
    };
    const onGroupBatchTransfer = (batch) => {
        setLoading(true);
        console.debug("On Group batch update")
        const response = api.post(`farm/${farmID}/group-batch-transfer/${batch.id}`, {
            ...batch,
            isGroupBatch:true,
            isTransferGroupBatch:true,
        });
        response.then((res) => {
            let index = groupBatches.findIndex((srcData) => srcData.id === batch.id);
            if (index !== -1) {
                let groupBatch = res
                let tempGroupBatches = [...res.transferGroupBatches,...res.groupBatches]
                groupBatch.groupBatches = tempGroupBatches
                groupBatch.transferGroupBatches = []
                setGroupBatch([
                    ...groupBatches.slice(0, index),
                    { ...groupBatches[index], ...groupBatch },
                    ...groupBatches.slice(index + 1)
                ]);
            }
            setShowGroupBatchDialog(false);
            setCurrentGroupBatchData([])
            removeErrorMessage();
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setGroupErrorMessage('Batch not updated');
                else if(err.message ===  'Duplicate Key')
                    setGroupErrorMessage('Duplicate Name');
                else if(err.message ===  'Given End Date is Greater than Start Date') {
                    setGroupErrorMessage('Given End Date is Greater than Start Date');
                    setShowBatchActivateDialog(false);
                } else if(err.message.includes('Already there is a daily entries for movement data.')) {
                    setGroupErrorMessage(err.message);
                } else {
                    setGroupErrorMessage(err.message)
                }
            } else {
                setGroupErrorMessage('Unknown Error')
            }
            
        }).finally(() => {
            setLoading(false);
        });
    };
    
    const onGroupBatchCost = (groupBatch) => {
        setLoading(true);
        const response = api.post(`farm/${farmID}/group-batch-cost/${groupBatch.id}`, {
            ...groupBatch,
            isGroupBatch:true,
        });
        response.then((res) => {
            let index = groupBatches.findIndex((srcData) => srcData.id === groupBatch.id);

            let tempGroupBatch = res
            let breedIndex = breeds.findIndex((breed) => breed.id === tempGroupBatch.breedID);
            tempGroupBatch.breedIndex = breedIndex
            if (index !== -1) {
                setGroupBatch([
                    ...groupBatches.slice(0, index),
                    { ...groupBatches[index], ...tempGroupBatch },
                    ...groupBatches.slice(index + 1)
                ]);
                setCurrentGroupBatchData(tempGroupBatch)
            }
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setGroupErrorMessage('Batch not updated');
                else setGroupErrorMessage(err.message)
            } else {
                setGroupErrorMessage('Unknown Error')
            }
            
        }).finally(() => {
            setLoading(false);
        });
    };
    function onGroupDeleteConfirm (){
        if (currentGroupBatchData && currentGroupBatchData.id) {
            setGroupBatchShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.post(`farm/${farmID}/delete-group-batch/${currentGroupBatchData.id}`,{...currentGroupBatchData});

            response.then(res => {
                if (res) {
                    let index = groupBatches?.findIndex((src) => {
                        return src.id === currentGroupBatchData.id;
                    });
                    let newData = [...groupBatches];
                    newData.splice(index, 1);
                    setGroupBatch(newData);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        setErrorMessage('Batch not Deleted');
                    else
                        setErrorMessage(err.message)
                } else {
                    setErrorMessage('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
               setCurrentGroupBatchData({})
            });

        }
    }
    function onDeleteConfirm (){
        if (currentData && currentData.id) {
            setShowDeleteConfirm(false);
            setLoading(true);
            showError('');
            const response = api.delete(`farm/${farmID}/batch/${currentData.id}`);

            response.then(res => {
                if (res) {
                    let index = batches.findIndex((src) => {
                        return src.id === currentData.id;
                    });
                    let newData = [...batches];
                    newData.splice(index, 1);
                    setBatch(newData);
                }
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        setErrorMessage('Batch not Deleted');
                    else
                        setErrorMessage(err.message)
                } else {
                    setErrorMessage('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
                currentData = undefined;
            });

        }
    }

    
    //Get all Shed API Call
    const getAllSheds = (batch) => {
        setLoading(true);
        showError('');
        showMessage('');
        const response = api.post(`farm/${farmID}/shed_for_batches`,{...batch});

        response.then(res => {
            sheds = [...res];
            currentData = {
                ...batch,
                breedIndex:breeds.findIndex((breed)=>{
                    return breed.id === batch.breedID;}),
                shedIndex:res.findIndex((shed)=>{
                    return shed.id === batch.shedID;})
            };
            setShowBatchDialog(true);

        }).catch(err => {
            if (err.message) {
                console.log(err.message)
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

        //Get all Shed API Call
        const getAllShedsForGroupBatch = (batch) => {
            setLoading(true);
            showError('');
            showMessage('');
            const response = api.post(`farm/${farmID}/shed_for_batches`,{...batch});
            response.then(res => {
                let tempshed= [...res]
                setAvailableSheds(tempshed)
                batch = {
                    ...batch,
                    breedIndex:breeds.findIndex((breed)=>{
                        return breed.id === batch.breedID;}),
                    shedIndex:tempshed.findIndex((shed)=>{
                        return shed.id === batch.shedID;})
                };
                setShowGroupBatchDialog(true)
            }).catch(err => {
                if (err.message) {
                    if (err.message === 'Bad Request' || err.message === 'No data found')
                        setGroupErrorMessage('Batch not updated');
                    else setGroupErrorMessage(err.message)
                } else {
                    setGroupErrorMessage('Unknown Error')
                }
            }).finally(() => {
                setLoading(false);
            });
        }

     //Get all Breeds API Call
     const getAllBreeds = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get('breed');

        response.then(res => {
            setBreeds([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    console.log(err.message)

                if (err.message === 'No data found'){
                    setAddBatchError('No Breed is Available');
                }
            } else {
                console.log('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //Get all Batch API Call
    const getAllBatches = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get(`farm/${farmID}/batch`);

        response.then(res => {
            setBatch([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('No Batches');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    //Get all Batch API Call
    // eslint-disable-next-line
    const getAllGroupBatches = () => {
        setLoading(true);
        showError('');
        showMessage('');

        const response = api.get(`farm/${farmID}/group-batch`);

        response.then(res => {
            setGroupBatch([...res]);
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    setErrorMessage('No Group Batches');
                else
                    setErrorMessage(err.message)
            } else {
                setErrorMessage('Unknown Error')
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const onload=()=>{
        getAllBreeds()
        getAllBatches()
        // getAllGroupBatches()
       
    }
    useEffect(onload, []);

    const handleIsActiveChange = (rowData) =>{
        currentData = ({
            ...rowData,
            isActive:!rowData.isActive,
            reactivate:true
        })
        setEditmode(true)
        if (rowData.isActive){
            setShowBatchActivateDialog(true)
        }else{
            onBatchUpdate(currentData)
        }  
    }


    let batchColumnDefaults = {title: 'Name',field: 'name', defaultSort:'asc'};
    let batchColumn = {...batchColumnDefaults,
        render: rowData => <Mobile data={rowData}
                                   onEdit={()=>{onBatchEdit(rowData)}}
                                   onDelete={()=>{onDelete(rowData)}}
                                   handleIsActiveChange={()=>{handleIsActiveChange(rowData)}}
        />};
    let actions = [];
    if (isDesktop) {
        batchColumn = batchColumnDefaults;
        actions = [
            rowData => ({
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Batch',
                onClick: (event, rowData) => {
                    onBatchEdit(rowData);
                },
                hidden:!rowData.isActive
            }),
            rowData => ({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Batch',
                onClick: (event, rowData) => {
                    onDelete(rowData);
                },
                hidden:!rowData.isActive
            })
        ]
    }

    let groupActions = []
    if (isDesktop) {
        batchColumn = batchColumnDefaults;
        groupActions = [
            rowData => ({
                icon: () => <Icon color='primary'>edit</Icon>,
                tooltip: 'Edit Batch',
                onClick: (event, rowData) => {
                    let BreedID = rowData?.breedID;
                    rowData.breedIndex = breeds.findIndex((breed) => breed.id === BreedID);
                    setCurrentGroupBatchData(rowData);
                    getAllShedsForGroupBatch({})
                    setGroupBatchEditMode(true)
                    setShowGroupBatchDialog(true)
                },
                hidden:!rowData.isActive
            }),
            rowData => ({
                icon: () => <Icon color='error'>delete</Icon>,
                tooltip: 'Delete Batch',
                onClick: (event, rowData) => {
                    onGroupBatchDelete(rowData);
                },
                hidden:!rowData.isActive
            })
        ]
    }
    return (
        <div className={classes.root}>
             <div id="error" className={classes.errorMargin}>
                    {errorMessage ? <Chip onDelete={removeErrorMessage} color="primary" label={errorMessage}/> : ''}
             </div>
            <Grid
                container
                justify='space-between'
                alignItems='flex-end'
                className={classes.topBar}
            >
                <Grid   style={{display:"flex"}}>
                    <Typography variant="h3" gutterBottom>
                        Batches
                    </Typography>
                    {/* <Typography variant="h5" gutterBottom style={{marginLeft:"20px"}}>
                        By Group View
                            <Switch
                                        checked={showGroupBatchView}
                                        onChange={()=>setShowGroupBatchView(!showGroupBatchView)}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                    </Typography> */}
                </Grid>

                <Grid>
                    {/* <Button variant="contained" color="primary"  onClick={onNewGroupBatch}>Add Group Batch</Button> */}
                    <Button variant="contained" color="primary" style={{marginLeft:"20px"}}  onClick={onNewBatch}>Add Batch</Button>
                </Grid>
            </Grid>
                {
                    showGroupBatchView ? 

                    <MaterialTable
                    isLoading={loading}
                    columns={[
                        {title: 'id', field: 'id', hidden: true},
                        batchColumn,
                        {title: 'Sub Batches', field: 'sheds',render:rowData => {
                           return  <div>

                            <Grid container spacing={1}>
                                {rowData?.groupBatches.map((batch, index) => {
                                    return (
                                        <Grid item key={batch.id}>
                                             <Tooltip title={batch.shedName} arrow>

                                                <Chip 
                                                className={classes.typeLabel}
                                                style={{background: "#E8F7F4"}} 
                                                    size="small"
                                                    label={`${batch.name}`} 
                                                />
                                             </Tooltip>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                           </div>
                        }},
                        {title: 'Purchase Date', field: 'purchaseDate', type: 'datetime',
                        editable: 'never',
                        hidden: !isDesktop,
                        render: rowData => {
                            return <span>{moment(rowData.purchaseDate).format("DD-MM-YYYY")}</span>
                        }},
                        {title: 'Active', field: 'isActive',hidden: !isDesktop,
                        cellStyle: {
                            textAlign: 'center'
                          },
                          headerStyle: {
                            textAlign: 'center',
                            paddingLeft: '42px'
                          },
                        render :rowData => {
                            return rowData.isActive ? 
                            <Switch
                                checked={rowData.isActive}
                                //disabled={!rowData.isActive}
                                onChange={()=>handleIsActiveChange(rowData)}
                                //value={rowData.isActive}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          : rowData.reactivate ?
                            <Switch
                                checked={rowData.isActive}
                                //disabled={!rowData.isActive}
                                onChange={()=>handleIsActiveChange(rowData)}
                                //value={rowData.isActive}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          :
                            <div style={{ textAlign: "center", minWidth: 'fit-content' }} className={classes.redChip}>Deactivated</div>
                        }}
                    ]}
                    data={groupBatches}
                    options={{
                        actionsColumnIndex: -1,
                        search: true,
                        showTitle: true,
                        
                        toolbar: true
                    }}
                    actions={groupActions}
                    title='Group Batches Table'
                />   
                    :
                    <MaterialTable
                        isLoading={loading}
                        columns={[
                            {title: 'id', field: 'id', hidden: true},
                            batchColumn,
                            {title: 'Shed', field: 'shedName', hidden: !isDesktop},
                            {title: 'Breed', field: 'breedName', hidden: !isDesktop},
                            {title: 'Number of Birds', field: 'numberOfBirds', hidden: !isDesktop},
                            {title: 'Age', field: 'age', hidden: !isDesktop},
                            {title: 'Purchase Date', field: 'purchaseDate', type: 'datetime',
                            editable: 'never',
                            hidden: !isDesktop,
                            render: rowData => {
                                return <span>{moment(rowData.purchaseDate).format("DD-MM-YYYY")}</span>
                            }},
                            {title: 'Active', field: 'isActive',hidden: !isDesktop,
                            cellStyle: {
                                textAlign: 'center'
                            },
                            headerStyle: {
                                textAlign: 'center',
                                paddingLeft: '42px'
                            },
                            render :rowData => {
                                return rowData.isActive ? 
                                <Switch
                                    checked={rowData.isActive}
                                    //disabled={!rowData.isActive}
                                    onChange={()=>handleIsActiveChange(rowData)}
                                    //value={rowData.isActive}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            : rowData.reactivate ?
                                <Switch
                                    checked={rowData.isActive}
                                    //disabled={!rowData.isActive}
                                    onChange={()=>handleIsActiveChange(rowData)}
                                    //value={rowData.isActive}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                            :
                                <div style={{ textAlign: "center", minWidth: 'fit-content' }} className={classes.redChip}>Deactivated</div>
                            }}
                        ]}
                        data={batches}
                        options={{
                            actionsColumnIndex: -1,
                            search: true,
                            showTitle: true,
                            
                            toolbar: true
                        }}
                        actions={actions}
                        title='Batches Table'
                    />   
                }
            <Batch deleteErrorMessage={removeErrorMessage} addBatchError={addBatchError} errorMessage={errorMessage} showDialog={showBatchDialog} handleClose={handleBatchClose} onSave={onBatchSave}
                  onEdit={onBatchUpdate} sheds={sheds} breeds={breeds} loading={loading}
                  editMode={editMode} data={currentData}
                  closeBreedDialog={closeBreedDialog}
                  showBreedDialog={showBreedDialog}
                  onBreedSave={onBreedSave} 
                  addBreedDialog={addBreedToggle}
                  />

                <GroupBatch deleteErrorMessage={removeErrorMessage} addBatchError={addBatchError} 
                errorMessage={groupErrorMessage} showDialog={showGroupBatchDialog} handleClose={handleBatchClose} 
                onSave={onGroupBatchSave}
                onEdit={onGroupBatchUpdate} sheds={availableSheds} breeds={breeds} loading={loading}
                editMode={groupBachEditMode} data={currentGroupBatchData}
                closeBreedDialog={closeBreedDialog}
                showBreedDialog={showBreedDialog}
                onBreedSave={onBreedSave} 
                addBreedDialog={addBreedToggle}
                onGroupBatchTransfer={onGroupBatchTransfer}
                onGroupBatchCost={onGroupBatchCost}
                  />

            <BatchActivate deleteErrorMessage={removeErrorMessage} errorMessage={errorMessage} showDialog={showBatchActivateDialog} handleClose={handleBatchActivateClose}
                onEdit={onBatchUpdate} sheds={sheds} breeds={breeds} loading={loading}
                editMode={editMode} data={currentData} />


                <ConfirmDialog showDialog={showGroupBatchDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onGroupDeleteConfirm}
                       title='Delete Group Batch ?'
                       subText='This action will delete the batch and also sub batches as well'/>
             <ConfirmDialog showDialog={showDeleteConfirm}
                       handleClose={handleDeleteConfirmClose}
                       onConfirm={onDeleteConfirm}
                       title='Delete Batch ?'
                       subText='This action will delete the batch.'/>
        </div>
    );
};

export default Batches;