import React, { useState, useRef, useEffect } from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import withStyles from "@material-ui/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import useTheme from "@material-ui/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import api from "../../common/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import html2canvas from "html2canvas";
import ReportInput from "./components/SingleBatchReportInput";
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import {useGlobalState} from '../../state';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { version } from "../../common/enums";
import TableWrapper from "../../components/Table/TableWrapper";
import { generateReportTitle } from "./utils/functions";

window.html2canvas = html2canvas;
const useStyles = makeStyles(theme => ({
  root: props => ({
    margin: props.isDesktop ? theme.spacing(4) : theme.spacing(1)
  }),
  topBar: {
    marginBottom: theme.spacing(2)
  },
  link: {
    display: "flex"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  formControl: {
    width: "100%"
  },
  progressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  progressBar: {
    margin: theme.spacing(2)
  },
  singleLineBorder: {
    borderTop: "solid 1px #F26522",
    borderBottom: "solid 1px #F26522"
  },
  doubleLineBorder: {
    borderTop: "double #F26522",
    borderBottom: "double #F26522"
  },
  reportContainer: {
    
  },
  table: {
    minWidth: 650,
  },
  reportHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  rawBatchName: {
    textAlign: "center",
    textDecoration: "underline",
    marginBottom: theme.spacing(2)
  },
  reminderTables: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  doctorNotesTables: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  expenseTableHeader: {
    fontWeight:"600  !important",
    padding: theme.spacing(2),

  }
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding:"6px",
    overflow: "hidden", 
    width:"90px"
  },
  body: {
    fontSize: "1em",
    backgroundColor: theme.palette.common.white,
    padding:"6px",
    overflow: "hidden", 
    width:"90px"
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default
    },
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  }
}))(TableRow);


const reminderTableHeader = "Shed Name, Details, Due in Days, Completed Date"
const doctorNotesTableHeader = "Shed Name, Notes, Completed Date"
const purchasesTableHeader = "Date, Item, Bill Value"
const generateExcel = function(header, data, vaccineNotifications, doctorNotes, purchases, eggSales, feedValue, fileName="report") {
  console.debug(header, data)
  let csvData = "";
  header.forEach((column, index) => {
    if (index === 0) csvData = csvData + column;
    else csvData = csvData + "," + column;
  });
  console.debug(csvData)
  csvData = csvData + "\r\n";
  console.debug(csvData)
  data.forEach(row => {
    row.forEach((column, index) => {
      if (index !== 6 && index !== 7) {
        if (index === 0) {
          csvData = csvData + column 
        }
        else if ((index === 19 && !
          eggSales) || (index === 18 && !feedValue)) {

        } else {
          csvData = csvData + "," + column;
        } 
    }

    });
    csvData = csvData + "\r\n";
  });
  if(vaccineNotifications.length >0){
    csvData = csvData + "\r\n";
    csvData = csvData + reminderTableHeader + "\r\n"
    vaccineNotifications.forEach((row, index)=>{
      let tempDueDays = "";
      if(!(row.completedDate.Valid)){
          if (row.dueDays > 0){ 
                tempDueDays = "Due - " + row.dueDays + " days" ;
          }else if (row.dueDays < 0) {
                tempDueDays = "OverDue - " + Math.abs(row.dueDays) + " days";
          }else{
                tempDueDays = "Today";
          } 
      }    
      csvData = csvData + row.shedName +","+ row.vaccineName +","+ tempDueDays +","+ 
      (row.completedDate.Valid ? moment(row.completedDate.Time).format("DD-MM-YYYY") : "") + "\r\n";
    })
  }

if(doctorNotes.length >0){
    csvData = csvData + "\r\n";
    csvData = csvData + doctorNotesTableHeader + "\r\n";
    doctorNotes.forEach((row, index)=>{ 
      csvData = csvData + row.shedName +","+ row.notes +","+ 
      (row.completionDate.Valid ? moment(row.completionDate.Time).format("DD-MM-YYYY") : "") + "\r\n";
    })
  }

if(purchases.purchaseOnBatchID !== undefined){
  if(purchases.purchaseOnBatchID.length >0){
      csvData = csvData + "\r\n";
      csvData = csvData + purchasesTableHeader + "\r\n";
      (purchases.purchaseOnBatchID).forEach((row, index)=>{ 
        csvData = csvData + (row.date.Valid ? moment(row.date.Time).format("DD-MM-YYYY") : "") + "," +
        row.item +","+ row.billValue + "\r\n";
      })
    }
  }
  console.log("csvData-----", csvData)

  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csvData)
  );
  element.setAttribute("download", `${fileName}.csv`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const SingleBatchWeekly = ({ showError, match }) => {
  const theme = useTheme();
  const [batch, setBatch] = useState("");
  const [shedType, setShedType] = useState("");
  // let tableHeader = JSON.parse(
  //   `[["Age","Opening Birds","Culls", "Mortality", "Feed In","Feed value","Good Eggs", "Total Eggs", "Feed In grams",
  //     "Egg Value", "Eggs/Bag", "HHEW", "HHET", "Egg %", "Good %", "Bro. %", "Damg. %"]]`
  // );

  const tableHeader = useRef( JSON.parse(
    `[["Age","Opening Birds","Culls", "Mortality", "Feed In","Good Eggs", "Total Eggs", "Feed In grams",
      "Eggs/Bag", "HHEW", "HHET", "Mortality %", "Egg %", "Good %", "Bro. %", "Damg. %","Feed Value","Sales Value"]]`
  ))

  const [{user}] = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [data, setData] = useState([]);
  const [bodyWeightData,setBodyWeightData] = useState([])
  const [summaryData,setSummaryData] = useState({})
  const [showBodyWeight,setshowBodyWeight] =useState(false)
  const [showDoctorNotes, setShowDoctorNotes] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [showPurchases, setShowPurchases] = useState(false);
    const [vaccineNotifications, setVaccineNotifications] = useState([]);
    const [doctorNotes, setDoctorNotes] = useState([]);
    const [purchases, setPurchases] = useState({});
    const [eggSales, setEggSales] = useState(user.versionID !== version.lite)
    const [feedValue, setFeedValue] = useState(user.versionID !== version.lite)
    const [reportFileName, setReportFileName] = useState("");
    const [showGroupBatchView, setShowGroupBatchView] = useState(false);
    const farmID = match.params['farmID'];

  const parseReportFromServer = function(data) {
    const { reportsArray } = data;
    setData(reportsArray);
    console.log(data);
  };

  const getChosenBatch = function(chosenValue) {
    setBatch(chosenValue);
  };

  // eslint-disable-next-line
  const handleGroupBatchView = () => {
    setShowGroupBatchView(!showGroupBatchView)
    setBatch("");
  };

  const getChosenShedType = function(chosenValue) {
    setShedType(chosenValue);
  };

    const amountObj = {
        style: "currency",
        currency: user.currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }

  const numToIndianStd = function (data, digits){
    console.debug(data,"data")
    if(!isNaN(data)){
    return data.toLocaleString(user.locales,amountObj);
    }
    };

    useEffect(() => {
      tableHeader.current = JSON.parse(`
      [["Age","Opening Birds","Culls", "Mortality", "Feed In","Good Eggs", "Total Eggs", 
      "Feed In grams","Eggs/Bag", "HHEW", "HHET", "Mortality %", "Egg %", "Good %", "Bro. %", "Damg. %"
      ${feedValue ? ',"Feed value"' : ''}${eggSales ? ',"Egg value"' : ''}]]
    `);
    
    }, [eggSales, feedValue])

  const generateReport = function() {
    setData([])
    showError("");
    setMainLoading(true);
    setLoading(true);
    onLoadReminder()
    let payload ={}

    if (shedType.id !== -1) {
       payload = {
        batchID: batch.id,
        shedTypeID: shedType.id,
        isGroupBatch:showGroupBatchView
      };
    } else {
      payload = {
        batchID: batch.id,
        isGroupBatch:showGroupBatchView
      };
    }


    const response = api.post("reports/single-batch-weekly", payload);

    response
      .then(res => {
        parseReportFromServer(res);
        if(res?.bodyWeightData){
          setBodyWeightData(res?.bodyWeightData)
          setshowBodyWeight(true)
        }else{
            setBodyWeightData([])
            setshowBodyWeight(false)
        }
        
        if(res?.summaryData){
           setSummaryData(res?.summaryData)
        }

        setReportFileName(generateReportTitle({batch: batch, shedType: shedType, reportName: "SingleBatchWeekly"}))
      })
      .catch(err => {
        if (err.message) {
          if (err.message === "Bad Request" || err.message === "No data found")
            showError(err.message);
        } else {
          showError("Unknown Error");
        }
        setData([])
      })
      .finally(() => {
        setMainLoading(false)
        setLoading(false);
      });
  };
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
  });
  const classes = useStyles({ isDesktop });

  const componentRef = useRef();
    const generatePDF = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: reportFileName,
      });
;

    const onLoadReminder = () => {
        setLoading(true);
        setShowReminder(false)
        setShowDoctorNotes(false)
        setShowPurchases(false)
        setVaccineNotifications([]);
        setDoctorNotes([])
        setPurchases([])
        showError('');
        const getCompletedVaccinationsResponse = api.post(`farm/${farmID}/complete-vaccinations-report`,{
            batchId : batch.id
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Reminder Notifications Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        const getAllDoctorNotesResponse = api.post(`farm/${farmID}/getDoctorNotesReport`,{
            batchId : batch.id
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Doctor Notes');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        const getAllPurchasesOnBatchIDResponse = api.post(`farm/${farmID}/purchasesOnBatchID`,{
            batchId : batch.id
        }).catch(err => {
            if (err.message) {
                if (err.message === 'Bad Request' || err.message === 'No data found')
                    showError('No Purchases Found');
                else
                    showError(err.message)
            } else {
                showError('Unknown Error')
            }
        });
        
        Promise.all([getCompletedVaccinationsResponse, getAllDoctorNotesResponse, getAllPurchasesOnBatchIDResponse]).then(results => {
            if (results[0]) {
                console.log("results[0]", results[0]);
                setVaccineNotifications([...results[0]]);
                setShowReminder(true)
            }
            if (results[1]) {
                setDoctorNotes([...results[1]])
                setShowDoctorNotes(true)
            }
            if (results[2]) {
                setPurchases({...results[2]})
                setShowPurchases(true)
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const displayColData = (col, colInd) => {
      if (col === '' || /[a-zA-Z]/.test(col)) {  
        return col
      }
      switch (colInd) {
        case 0: case 4: case 5: case 6: case 7: case 8: // Age, Feed In, Good, Broken, Damage, Total Eggs
          return Math.round(col)
        case 13: case 14: case 15: case 16: case 17:// Egg &,Mort %, Good %, Bro. %, Damg. %
         return parseFloat(col).toFixed(1)
        default:
          return parseFloat(col).toFixed(2)
      }
    }

  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" className={classes.topBar}>
        <Grid style={{display:"flex"}}>
          <Typography display="block" variant="h3" gutterBottom>
            Single Batch Weekly Report
          </Typography>
          {/* <Typography variant="h5" gutterBottom style={{marginLeft:"20px"}}>
                  Group Batches
                      <Switch
                          checked={showGroupBatchView}
                          onChange={handleGroupBatchView}
                          color="primary"
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
           </Typography> */}
        </Grid>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={3} justify="flex-start" alignItems="center">
          <Grid item   xs={isDesktop ? 5 :12}>
            <ReportInput
              showError={showError}
              match={match}
              setValid={setValid}
              getChosenBatch={getChosenBatch}
              getChosenShedType={getChosenShedType}
              setLoading={setLoading}
              showGroupBatchView={showGroupBatchView}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={!valid}
              variant="contained"
              color="primary"
              onClick={generateReport}
            >
              Generate
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!valid || data?.length < 1}
              variant="outlined"
              color="primary"
              onClick={generatePDF}
              endIcon={<PictureAsPdfIcon />}
            >
              Download PDF
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!valid || data.length < 1}
              variant="outlined"
              color="primary"
              onClick={() => generateExcel(tableHeader.current, data, vaccineNotifications, doctorNotes, purchases, eggSales, feedValue, reportFileName)}
              endIcon={<GetAppIcon />}
            >
              Download Excel
            </Button>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <br/>
      {
        (user.versionID !== version.lite) &&
        <div>
        <FormControlLabel
          control={
            <Switch
              checked={feedValue}
              onChange={() => setFeedValue(!feedValue)}
              color="primary"
            />
          }
          label="Feed Value"
        />
        <FormControlLabel
          control={
            <Switch
              checked={eggSales}
              onChange={() => setEggSales(!eggSales)}
              color="primary"
            />
          }
          label="Egg Sales"
        />
      </div>
      }
      {loading ? (
        <div className={classes.progressContainer}>
          <CircularProgress className={classes.progressBar} />
        </div>
      ) : (
        <div id="report-container" ref={componentRef} className="reportPdfContainer">
          <div className={classes.reportHeader}>
            <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            >
              {batch.id && batch.name !== ""
                ? `Single Batch Weekly Report ${batch.name}` +
                  (shedType !== "" ? ` at ${shedType.shed_type}` : "")
                : ""}
            </Typography>
          </div>
          <TableWrapper>
          <Table  style={{ tableLayout: "auto", maxWidth: "100%"}}
          stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Age</StyledTableCell>
                <StyledTableCell>Op.Birds</StyledTableCell>
                <StyledTableCell>Culls</StyledTableCell>
                <StyledTableCell>Mort.</StyledTableCell>
                {/* <StyledTableCell>Closing Birds</StyledTableCell> */}
                <StyledTableCell>Feed In</StyledTableCell>     
                <StyledTableCell>Good Eggs</StyledTableCell>
                {/* <StyledTableCell>Broken Eggs</StyledTableCell>
                <StyledTableCell>Damaged Eggs</StyledTableCell> */}
                <StyledTableCell>Total Eggs</StyledTableCell>
                <StyledTableCell>Avg Feed/bird (gms)</StyledTableCell>         
                {/* <StyledTableCell>Std. Feed</StyledTableCell> */}
                <StyledTableCell>Eggs/Bag</StyledTableCell>
                <StyledTableCell>HHEW</StyledTableCell>
                <StyledTableCell>HHET</StyledTableCell>
                <StyledTableCell>Mort. %</StyledTableCell>
                <StyledTableCell>Egg %</StyledTableCell>
                <StyledTableCell>Good. %</StyledTableCell>
                <StyledTableCell>Bro. %</StyledTableCell>
                <StyledTableCell>Damg. %</StyledTableCell>
                {feedValue && <StyledTableCell>Feed Value</StyledTableCell> }
                {eggSales && <StyledTableCell>Sales Value</StyledTableCell> }
                {/* <StyledTableCell>Std %</StyledTableCell> */}
                {/* <StyledTableCell>Diff. egg production</StyledTableCell> */}
                {/* <StyledTableCell>Diff. Feed/grams</StyledTableCell> */}
              </StyledTableRow>
            </TableHead>
           <TableBody style={{position:"relative"}}>

                <div  style={{
                  position:"absolute",
                  top:0,
                  left: "50%",
                  display: mainLoading ? 'block' : 'none'
                }}>
                  <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progressBar} />
                  </div>
                </div>
            
            {data.map((row, rowInd) => {
              return <StyledTableRow key={rowInd}>
                {row.map((col, colInd) => {
                  /*
                      Hide based on switches
                      19 - Egg sales
                      18 - Feed value 
                    */
                    if ((colInd === 19 && !eggSales) || (colInd === 18 && !feedValue)) {
                      return null
                    }
                  if (colInd !== 6 && colInd !== 7) { // Hide Broken and Damaged egg
                    return <StyledTableCell key={colInd} 
                    className={
                      row[0] === "" ? classes.singleLineBorder : undefined
                    }>
                    {displayColData(col, colInd)}
                  </StyledTableCell>
                  }
                  return null
                  
                })}
              </StyledTableRow>
            })}
             {/*  {data.map((row, index) => (
                <StyledTableRow key={index}>
                {row.map((cols, col) => (
                  (col===8) || (col===9) || (col===5 && !showFeedValue) || (col===11 && !eggSalesValue) ? "" :
                  (
                  <StyledTableCell align="left" key = {col}
                  className={
                      row[0] === "" ? classes.singleLineBorder : undefined
                    }>
                      {(col === 1 && row[10]=== "") || (col === 3 && row[10]=== "") ?
                       ``+ numToIndianStd(parseInt(cols),2)
                      :""}
                      
                      {col === 11 && !isNaN(parseInt(cols)) ?
                        parseFloat(cols).toFixed(2)
                      :''}
                    {((col === 1 && row[10]=== "") || (col === 3 && row[10]=== "")) || (col === 11 && !isNaN(parseInt(cols))) ?
                    "" :(
                        (!isNaN(cols) && cols.toString().indexOf('.') !== -1)?
                            (col === 4 ? Math.round(cols) : parseFloat(cols).toFixed(2))
                            :
                            cols.toLocaleString(user.locales,amountObj)
                    )
                    }
                  </StyledTableCell>)
                  ))}
                </StyledTableRow>
              ))} */}
            </TableBody>
          </Table>
          </TableWrapper>
          {  !(Object.values(summaryData).every(value => value === "")) &&(<Grid xs={isDesktop ? 8 : 12} className={classes.doctorNotesTables} container>
          <TableWrapper>
          <Table //style={{ tableLayout: "fixed"}} 
          stickyHeader aria-label="sticky table"colSpan={4}>
            <TableHead colSpan={4}>
            <StyledTableRow
             align="center" >
                <StyledTableCell  align="center" colSpan={4}>Expense</StyledTableCell>
                <StyledTableCell  align="center" colSpan={2}>Income</StyledTableCell>
                <StyledTableCell align="center" colSpan={2}>Batchcost</StyledTableCell>
              </StyledTableRow>
            </TableHead>
           <TableBody style={{position:"relative"}} >
                <StyledTableRow
                align="center" >
                          <StyledTableCell align="center" className={classes.expenseTableHeader} fontSize="6px !important">Day Old Chick</StyledTableCell> 
                          <StyledTableCell align="center"  >{summaryData.ChickAmount &&(parseFloat(summaryData.ChickAmount).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center" className={classes.expenseTableHeader} >Labour</StyledTableCell> 
                          <StyledTableCell align="center" >{summaryData.Labour &&(parseFloat(summaryData.Labour).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center" className={classes.expenseTableHeader}>Egg Sales</StyledTableCell> 
                          <StyledTableCell align="center">{summaryData.EggSales &&(parseFloat(summaryData.EggSales).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center"className={classes.expenseTableHeader} >Net Balance</StyledTableCell> 
                          <StyledTableCell align="center" >{summaryData.NetBalance &&(parseFloat(summaryData.NetBalance).toFixed(2))}</StyledTableCell>       
                </StyledTableRow>
                <StyledTableRow
                align="center" >
                          <StyledTableCell align="center" className={classes.expenseTableHeader} >Feed Cost</StyledTableCell> 
                          <StyledTableCell align="center"  >{summaryData.TotalFeedCost &&(parseFloat(summaryData.TotalFeedCost).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center" className={classes.expenseTableHeader}>Other Labour</StyledTableCell> 
                          <StyledTableCell align="center" >{summaryData.OtherLabour &&(parseFloat(summaryData.OtherLabour ).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center"className={classes.expenseTableHeader} >Culls</StyledTableCell> 
                          <StyledTableCell align="center" >{summaryData.Culls&&(parseFloat(summaryData.Culls).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center"className={classes.expenseTableHeader} >Cost/bird</StyledTableCell> 
                          <StyledTableCell align="center"  >{summaryData.CostPerBird&&(parseFloat(summaryData.CostPerBird).toFixed(2))}</StyledTableCell>       
                </StyledTableRow>  
                <StyledTableRow
                align="center" >
                          <StyledTableCell align="center" className={classes.expenseTableHeader}>Medicine</StyledTableCell> 
                          <StyledTableCell align="center" >{summaryData.Medicine&&(parseFloat(summaryData.Medicine).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center"className={classes.expenseTableHeader}>EB</StyledTableCell> 
                          <StyledTableCell align="center"  >{summaryData.EB&&(parseFloat(summaryData.EB).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center" className={classes.expenseTableHeader}>Manure</StyledTableCell> 
                          <StyledTableCell align="center" >{summaryData.ManureIncome&&(parseFloat(summaryData.ManureIncome).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center"className={classes.expenseTableHeader}></StyledTableCell> 
                          <StyledTableCell align="center"  ></StyledTableCell>       
                </StyledTableRow>  
                <StyledTableRow
                align="center" >
                          <StyledTableCell align="center"className={classes.expenseTableHeader} >Vaccination</StyledTableCell> 
                          <StyledTableCell align="center" >{summaryData.Vaccination&&(parseFloat(summaryData.Vaccination).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center"className={classes.expenseTableHeader} >Misc</StyledTableCell> 
                          <StyledTableCell align="center" >{summaryData.MiscExpense&&(parseFloat(summaryData.MiscExpense).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center" className={classes.expenseTableHeader}>Misc</StyledTableCell> 
                          <StyledTableCell align="center" >{summaryData.MiscIncome&&(parseFloat(summaryData.MiscIncome).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center"></StyledTableCell> 
                          <StyledTableCell align="center"></StyledTableCell>       
                </StyledTableRow>
                <StyledTableRow
                align="center" >
                          <StyledTableCell align="center"className={classes.expenseTableHeader}>Total</StyledTableCell> 
                          <StyledTableCell align="center">{summaryData.TotalExpense&&(parseFloat(summaryData.TotalExpense).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center"></StyledTableCell> 
                          <StyledTableCell align="center">{summaryData.TotalOtherExpense&&(parseFloat(summaryData.TotalOtherExpense).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center"></StyledTableCell> 
                          <StyledTableCell align="center">{summaryData.TotalIncome&&(parseFloat(summaryData.TotalIncome).toFixed(2))}</StyledTableCell>
                          <StyledTableCell align="center"></StyledTableCell> 
                          <StyledTableCell align="center"></StyledTableCell>       
                </StyledTableRow>     
               
          
           
            </TableBody>
          </Table>
          </TableWrapper>
          </Grid>)}
         
        
          <Grid xs ={12} container>
         
          {showBodyWeight ? 
          <Grid  className={classes.doctorNotesTables} item xs={isDesktop ? 6 : 12} >
          <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            > Body Weight
            </Typography>
           <TableWrapper>
           <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Age</StyledTableCell>
                <StyledTableCell>Body Weight</StyledTableCell>
                <StyledTableCell>Uniformity</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {(bodyWeightData).map((row, index)=>(
                <StyledTableRow key={index}>
                <StyledTableCell>{row[1]}</StyledTableCell>
                <StyledTableCell>{row[0]}</StyledTableCell>
                <StyledTableCell>{row[2]}</StyledTableCell>
                <StyledTableCell>{row[3]}</StyledTableCell>
                </StyledTableRow>
              ))}

            </TableBody>
          </Table>
          </TableWrapper>
          </Grid>
          : null
          }
       
          
          {showPurchases ? 
          <Grid xs={isDesktop ? 6 : 12} className={classes.doctorNotesTables} item>
          <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            > Purchases
            </Typography>
            <TableWrapper>
           <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Item</StyledTableCell>
                <StyledTableCell>Bill Value</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {(purchases.purchaseOnBatchID).map((row, index)=>(
                <StyledTableRow key={index}>
                <StyledTableCell>{row.date.Valid ? moment(row.date.Time).format("DD-MM-YYYY") : ""}</StyledTableCell>
                <StyledTableCell>{row.item}</StyledTableCell>
                <StyledTableCell>{``+ numToIndianStd(parseFloat(row.billValue),2)}</StyledTableCell>
                </StyledTableRow>
              ))}
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>Total</StyledTableCell>
                <StyledTableCell>{``+numToIndianStd(parseFloat(purchases.totalBillValue),2) }</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
          </TableWrapper>
          </Grid>
          : null
          }
           {showReminder ?
          <Grid xs={isDesktop ? 6 : 12} className={classes.reminderTables} item>
           <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            > Vaccinations
            </Typography>
          <TableWrapper>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Details</StyledTableCell>
                <StyledTableCell>Due in Days</StyledTableCell>
                <StyledTableCell>Completed Date</StyledTableCell>
                <StyledTableCell>Batch Number</StyledTableCell>
                <StyledTableCell>Manufacture Date</StyledTableCell>
                <StyledTableCell>Expiry Date</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
                {vaccineNotifications.map((row, index)=>(
                <StyledTableRow key={index}>
                <StyledTableCell>{row.vaccineName}</StyledTableCell>
                <StyledTableCell>{
                  !row.completedDate.Valid ? (row.dueDays > 0 ? 
                "Due - " + row.dueDays + " days" 
                : 
                row.dueDays < 0 ?
                "OverDue - " + Math.abs(row.dueDays) + " days"
                :
                "Today"
                ):null
                }</StyledTableCell>
                <StyledTableCell>{row.completedDate.Valid ? 
                moment(row.completedDate.Time).format("DD-MM-YYYY") : ""}</StyledTableCell>
                <StyledTableCell>{row.batchNumber}</StyledTableCell>
                <StyledTableCell>{
                  row.mfgDate !== "" ? new Date(row.mfgDate).toLocaleDateString() : ""
                }</StyledTableCell>
                <StyledTableCell>{
                  row.expDate !== "" ? new Date(row.expDate).toLocaleDateString() : ""
                }</StyledTableCell>
                </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          </TableWrapper>
          </Grid>
          : null
          }
          </Grid>
         
         
         
          {showDoctorNotes ? 
          <Grid  xs={isDesktop ? 6 : 12} className={classes.doctorNotesTables} item>
          <Typography
              variant="h4"
              component="h4"
              className={classes.rawBatchName}
            > Doctor Notes
            </Typography>
           <TableWrapper>
           <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Notes</StyledTableCell>
                <StyledTableCell>Completed Date</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {doctorNotes.map((row, index)=>(
                <StyledTableRow key={index}>
                <StyledTableCell>{row.notes}</StyledTableCell>
                <StyledTableCell>{row.completionDate.Valid ? 
                moment(row.completionDate.Time).format("DD-MM-YYYY") : ""}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          </TableWrapper>
          </Grid>
          : null
          }
         
        
        </div>
      )}
    </div>
  );
};

export default SingleBatchWeekly;
